const usersRoutes = [

  {
    path: "/users",
    name: 'User Management',
    component: () => import("../User.vue"),
    meta: { requiresAuth: true }
  }
];

export default usersRoutes;
