const academicYearsRoutes = [
  {
    path: "/academic-years",
    name: "Academic Years",
    component: () => import("../AcademicYears"),
    meta: { requiresAuth: true }
  },


];

export default academicYearsRoutes;
