const subMenuRoutes = [
  {
    path: "/sub-menus",
    name: 'Sub menus',
    component: () => import("../SubMenu.vue"),
    meta: { requiresAuth: true }
  },


];

export default subMenuRoutes;
