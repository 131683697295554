const smsRoutes = [
  {
    path: "/sms",
    name: 'SMS',
    component: () => import("../Sms"),
    meta: { requiresAuth: true }
  },



  {
    path: "/mailing",
    component: () => import("../Mailing"),
    meta: { requiresAuth: true }
  },


];

export default smsRoutes;
