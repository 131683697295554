const schoolProfileRoutes = [

  {
    path: "/school-profile",
    name: 'School Profile',
    component: () => import("../SchoolProfile"),
    meta: { requiresAuth: true }
  },


];

export default schoolProfileRoutes;
