const locationRoutes = [
  {
    path: "/manage-locations",
    name: 'Admin Areas',
    component: () => import("../Location"),
    meta: { requiresAuth: true }
  },




];

export default locationRoutes;
