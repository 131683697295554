<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({}),

  mounted() {},
};
</script>
<style>
#App {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
.v-main__wrap {
  margin-left: 5px;
}
</style>
