<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">Change Default Password</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="New Password"
                    v-model="formData.password"
                    required
                    :rules="isValid.passwordStrength"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Repeat Password"
                    v-model="formData.repeatPassword"
                    required
                    :rules="isValid.passwordMatch"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="'change'"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false, required: true },
    closeDialog: { type: Function, required: true },
    saveFunction: { type: Function, required: true },
    loading: { type: Boolean, default: false, required: true },
    // formData: { type: Object, required: true },
  },
  name: "PasswordDialog",
  data() {
    return {
      formData: {},
      valid: true,
      isValid: {
        password: (v) => !!v || " Name required",

        passwordStrength: [(v) => this.passwordStrength(v)],

        passwordMatch: [(v) => this.passwordMatch(v)],
      },
    };
  },
  methods: {
    passwordStrength(v) {
      if (
        !new RegExp(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,15}$/
        ).test(v)
      ) {
        return "Password with minimum length 6 characters,not more than 15, contain 1 special Character,1 -Integer and at least 1 uppercase letter ";
      }
      if (v == undefined || v == "") {
        return " Field required";
      } else {
        return true;
      }
    },
    passwordMatch(v) {
      if (v != this.formData.password) {
        return "Password Mismatch";
      }
      if (v == undefined || v == "") {
        return " Field required";
      } else {
        return true;
      }
    },
    add() {
      let data = { ...this.formData };

      this.saveFunction(data);
    },

    close() {
      this.closeDialog();
    },
  },
  computed: {},
  mounted() {},
};
</script>
