const mainMenuRoutes = [

  {
    path: "/main-menus",
    name: 'Main Menus',
    component: () => import("../MainMenu.vue"),
    meta: { requiresAuth: true }
  }
];

export default mainMenuRoutes;
