

const uppercase = {
    bind: function (el, _, vnode) {
        // uppercase transform
        el.transformUpper = function () {


            var data = el.querySelectorAll("input")[0]
            data.value = data.value.toUpperCase()

            vnode.componentInstance.$emit('input', data.value.toUpperCase())
        }

        // add event listener
        el.addEventListener('input', function () {
            el.transformUpper()
        })
    },
    unbind: function (el) {
        el.removeEventListener('input', el.transformUpper)
    }

}
const lowercase = {
    bind: function (el, _, vnode) {
        // uppercase transform
        el.transformUpper = function () {


            var data = el.querySelectorAll("input")[0]
            data.value = data.value.toUpperCase()

            vnode.componentInstance.$emit('input', data.value.toLowerCase())
        }

        // add event listener
        el.addEventListener('input', function () {
            el.transformUpper()
        })
    },
    unbind: function (el) {
        el.removeEventListener('input', el.transformUpper)
    }

}


const sentenceCase = {
    bind: function (el, _, vnode) {
        // uppercase transform
        el.transformUpper = function () {


            var data = el.querySelectorAll("input")[0]
            data.value = toSentence(data.value)

            vnode.componentInstance.$emit('input', toSentence(data.value))
        }

        // add event listener
        el.addEventListener('input', function () {
            el.transformUpper()


        })
    },

    unbind: function (el) {
        el.removeEventListener('input', el.transformUpper)
    }

}


function toSentence(s) { return s.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); }); }

export { uppercase, sentenceCase, lowercase }