import Vue from 'vue'
import vMultiselectListbox from 'vue-multiselect-listbox'

Vue.component('ConfirmDialog', () => import('./ConfirmDelete.vue'))
Vue.component('SnackBar', () => import('./SnackBar.vue'))
Vue.component('Loader', () => import('./Loader'))
Vue.component('CreateButton', () => import('./CreateButton.vue'))
Vue.component('AddButton', () => import('./AddButton.vue'))
Vue.component('DialogButtons', () => import('./DialogButtons.vue'))
Vue.component('DualSelects', () => import('./DualMultiSelect'))
Vue.component('DatePicker', () => import('./DatePicker'))
Vue.component('ActionsButtons', () => import('./ActionsButtons'))
Vue.component('SearchField', () => import('./SearchField'))
Vue.component('eExamTree', () => import('./eExamTree'))
Vue.component('FacilitiesLoader', () => import('./facilityLoader'))
Vue.component('BaseDropdown', () => import('./BaseDropdown.vue'))
Vue.component('SweetAlert', () => import('./SweetAlert.vue'))
Vue.component('ConfirmAction', () => import('./ConfirmAction.vue'))
Vue.component('PageTitle', () => import('./PageTitle.vue'))
Vue.component('FloatingButton', () => import('./FloatButton.vue'))
Vue.component('EFacilities', () => import('./eFacilitiesLoader.vue'))
Vue.component('ConfirmDeleteResolve', () => import('./ConfirmDeleteResolve.vue'))
Vue.component('LoadExaminations', () => import('../exams-scores/components/LoadExamComponent.vue'))
Vue.component('ImageUploader', () => import('./ImageUploader.vue'))
Vue.component('RegionDistricts', () => import('./RegionAndLGAOnly.vue'))
Vue.component('v-multiselect-listbox', vMultiselectListbox)





