const dataOptionsRoutes = [
  {
    path: "/data-options",
    name: 'Data Options',
    component: () => import("../MetadataOption"),
    meta: { requiresAuth: true }
  },


  {
    path: "/data-categories",
    name: 'Data Categories',
    component: () => import("../DataCategory"),
    meta: { requiresAuth: true }
  }
];

export default dataOptionsRoutes;
