// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import store from '../store'
//import '@/sass/overrides.sass'


Vue.use(Vuetify)


// let color = JSON.parse(localStorage.getItem("theme"))
store.commit("getItem", "theme")
let color = store.getters.getThemeColor


const theme = {


  primary: color && color.code ? color.code : color && color.hex ? color.hex : colors.teal,
  accent: '#9C27b0',
  info: '#00CAE3',
  danger: '#ff5722'
}
const opts = {

  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
}




export default new Vuetify({

  ...opts,
  data: () => {
    return {
      themes: colors.lightBlue,
    }
  }
}

) 