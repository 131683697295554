const staffRoutes = [

  {
    path: "/staff",
    name: 'Staff Management',
    component: () => import("../Staff.vue"),
    meta: { requiresAuth: true }
  },



  {
    path: "/downloadTange",
    name: 'Staff Management',
    component: () => import("../Staff.vue"),
    meta: { requiresAuth: true }
  }
];

export default staffRoutes;
