const examsAttendanceRoutes = [
  {
    path: "/examination-attendance",
    name: 'Exams Attendance',
    component: () => import("../ExamsAttendance"),
    meta: { requiresAuth: true }
  },


];

export default examsAttendanceRoutes;
