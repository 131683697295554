import Vue from 'vue';
import axios from "axios";

import SecureLs from 'secure-ls';
import notify from './Helpers'

const ls = new SecureLs({ encodingType: 'des', isCompression: false, encryptionSecret: 'eAMIS!@#' })


//set current users headers and token

axios.defaults.headers.common["Accept"] = `application/json`;
axios.defaults.headers.common["Content-Type"] = `application/json`;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;



const clearConsole = () => {
    let mode = process.env.VUE_APP_MODE
    if (mode === 'production' && (window.console || window.console.firebug)) {
        console.clear();
    }
}

const requestHandler = request => {
    return request;
};

// Add interceptors
axios.interceptors.request.use(request => {
    //set current users headers and token
    // console.log(request)
    const currentUser = ls.get("eExam-user");


    request.headers["Authorization"] = currentUser ? `Bearer ${currentUser.token}` : null

    clearConsole()
    return requestHandler(request)
},
    (error) => {
        clearConsole()
        return Promise.reject(error);
    }
);



axios.interceptors.response.use(

    response => {
        clearConsole()
        return notify.successHandler(response)
    },

    error => {
        clearConsole()
        return notify.errorHandler(error)
    }
);

axios.interceptors.response.use((response) => {
    clearConsole()
    return response;
}, (error) => {
    clearConsole()
    //console.log('error,', error);

    if (error?.response?.status === 429) {
        // If the error has status code 429, retry the request
        //  const currentUser = store.getters.currentUser;
        const currentUser = ls.get("eExam-user");
        const token = currentUser.token;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        return axios.request(error.config);

    }
    return Promise.reject(error);
});

