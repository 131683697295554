
const state = {
  snackbar: {
    show: true,
    message: "",
    color: "",
    icon: ""
  },
  titles: null,
};

const getters = {
  getSnackBar: state => {
    return state.snackbar;
  },

  getPageTitle: state => {

    return state.titles
  },
};

const actions = {
  notify({ commit }, payload) {

    commit("setSnackbar", payload);
  },

  async triggerPageTitle({ commit }, payload) {


    commit('setPageTitle', payload);
  }
};

const mutations = {
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },

  setPageTitle(state, payload) {
    state.titles = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
