const salaryScalesRoutes = [
  {
    path: "/salary-scales",
    name: 'Manage Employee Salary Scale',
    component: () => import("../SalaryScale"),
    meta: { requiresAuth: true }
  },


];

export default salaryScalesRoutes;
