const levelRoutes = [
  {
    path: "/levels",
    name: 'Manage Levels',
    component: () => import("../Level"),
    meta: { requiresAuth: true }
  },


];

export default levelRoutes;
