const divisionGradesRoutes = [
  {
    path: "/division-grading",
    name: "Division Grading",

    component: () => import("../DivisionGrades"),
    meta: { requiresAuth: true }
  },


];

export default divisionGradesRoutes;
