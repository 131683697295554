const studentSubjectsRoutes = [
  {
    path: "/students-by-subjects",
    name: 'Students Subjects',
    component: () => import("../StudentSubjects"),
    meta: { requiresAuth: true }
  },


];

export default studentSubjectsRoutes;
