const subjectRoutes = [
  {
    path: "/manage-subjects",
    name: 'Manage Subjects',
    component: () => import("../Subject"),
    meta: { requiresAuth: true }
  },


];

export default subjectRoutes;
