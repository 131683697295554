import Vue from 'vue'
import store from '@/store'
import router from '@/router'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';



if (!VueSweetalert2) {
    throw new Error('you should npm install `vue-sweetalert2` --save at first ')
}
Vue.use(VueSweetalert2);
import '@/plugins/SweetAlerts'
const sweetAlert = {}

const showErrorSweetAlert = response => {
    //sweetAlerts Segment
    sweetAlert.icon = 'error';
    sweetAlert.title = 'Failed...';
    sweetAlert.html = `<h3 style="color:red">${response?.data?.errors} .</h3>`;
    if (response.data.errors) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset


    }

};

const show500ErrorSnackbar = response => {
    // console.log("500", response.statusText);

    //sweetAlerts Segment
    sweetAlert.icon = 'error';
    sweetAlert.title = 'Failed...';
    sweetAlert.html = `<h3 style="color:red">500:${response.statusText} .</h3>`;
    if (response.statusText) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset
    }
};

const show401ErrorSweetAlert = response => {
    //console.log("401", response);

    //sweetAlerts Segment
    sweetAlert.icon = 'error';
    sweetAlert.title = 'Action Failed...';
    sweetAlert.html = `<h3 style="color:red">401:${response.statusText} .</h3>`;
    if (response.statusText) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset
    }
};
const show409ErrorSweetAlert = response => {

    //sweetAlerts Segment
    sweetAlert.icon = 'error';
    sweetAlert.title = 'Action Failed...';
    sweetAlert.html = `<h3 style="color:red">409:${response.data.message} .</h3>`;
    if (response.data.message) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset
    }
};
const show400ErrorSweetAlert = response => {


    //sweetAlerts Segment
    sweetAlert.icon = 'error';
    sweetAlert.title = 'Action Failed...';
    sweetAlert.html = `<h3 style="color:red">400:${response.data.title ? response.data.title : response.data.message} .</h3>`;
    if (response.data.title || response.data.message) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset
    }
    store.commit('LOGOUT');
    router.push("/login");//session expired go to login page
};
const show422ErrorSweetAlert = response => {
    let validationErrors = Object.values(response.data.errors).flat();

    //sweetAlerts Segment
    let html = '';


    validationErrors.forEach(function (value) {
        html += "<li>" + value + "</li>"

        console.log(value);
    });

    sweetAlert.icon = 'error';
    sweetAlert.title = 'Action Failed...';
    sweetAlert.html = `<h3 style="color:red; text-align:left"> Error Code - 422:${html}</h3>`;
    if (response.data.errors) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset
    }
};
const show404ErrorSweetAlert = response => {

    //sweetAlerts Segment
    sweetAlert.icon = 'error';
    sweetAlert.title = 'Action Failed...';
    sweetAlert.html = `<h3 style="color:red">404:${response.data.title ? response.data.title : response.data.message} .</h3>`;
    if (response.data.title || response.data.message) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset
    }
};

const show405ErrorSweetAlert = response => {
    // console.log("405", Session xpired);

    //sweetAlerts Segment
    sweetAlert.icon = 'error';
    sweetAlert.title = 'Action Failed...';
    sweetAlert.html = `<h3 style="color:red">405:${response.data.title ? response.data.title : response.data.message} .</h3>`;
    if (response.data.title || response.data.message) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset
    }

};

const show403ErrorSweetAlert = response => {
    // console.log("400", response);

    //sweetAlerts Segment
    sweetAlert.icon = 'error';
    sweetAlert.title = 'Action Failed...';
    sweetAlert.html = `<h3 style="color:red">403:${response.data.title ? response.data.title : response.data.message} .</h3>`;
    if (response.data.title || response.data.message) {
        store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
        store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset
    }
};
const errorHandler = (error) => {
    //console.log('errors kk ',error.response.data.errors);

    //const errors=Object.values(error?.response?.data?.errors).flat();



    switch (error?.response?.status) {
        case 500:
            show500ErrorSnackbar(error.response);
            break;

        case 400:
            show400ErrorSweetAlert(error.response);
            break;
        case 401:
            show401ErrorSweetAlert(error.response);
            break;
        case 403:
            show403ErrorSweetAlert(error.response);
            break;
        case 404:
            show404ErrorSweetAlert(error.response);
            break;
        case 405:
            show405ErrorSweetAlert(error.response);
            break;
        case 409:
            show409ErrorSweetAlert(error.response);
            break;
        case 422:
            show422ErrorSweetAlert(error.response);
            break;
        default:
            showErrorSweetAlert(error.response);
    }
    return Promise.reject({ ...error });
};

const successHandler = (response) => {

    switch (response.config.method) {
        case "put":
        case "post":
        case "delete":


            //sweetAlerts Segment
            sweetAlert.icon = 'success';
            sweetAlert.title = 'Successful...';
            sweetAlert.html = `<h3 style="color:green">${response?.data?.message} .</h3>`;

            if (response.data.message) {
                store.state.SweetAlert.sweetAlerts = {};//resets keys of Sweet alerts
                store.state.SweetAlert.sweetAlerts = sweetAlert;//sets keys after reset


            }



    }

    //store.state.SweetAlert.sweetAlerts = {};

    return response;
};

export { successHandler, errorHandler }
