const examScoresRoutes = [
  {
    path: "/capture-scores",
    name: 'Capture Scores',
    component: () => import("../ExamsScores"),
    meta: { requiresAuth: true }
  },


];

export default examScoresRoutes;
