const universityRoutes = [
  {
    path: "/universities",
    name: 'Manage University',
    component: () => import("../University"),
    meta: { requiresAuth: true }
  },


];

export default universityRoutes;
