const roleRoutes = [
  {
    path: "/roles",
    name: 'Manage Roles',
    component: () => import("../Role"),
    meta: { requiresAuth: true }
  },


];

export default roleRoutes;
