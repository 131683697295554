const schoolClassesRoutes = [
  {
    path: "/school-classes",
    name: 'School Classes',
    component: () => import("../SchoolClasses"),
    meta: { requiresAuth: true }
  },


];

export default schoolClassesRoutes;
