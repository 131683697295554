import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js';
import componentsRouter from './ComponentRoutes'

import SecureLs from 'secure-ls';

const ls = new SecureLs({ encodingType: 'des', isCompression: false, encryptionSecret: 'eAMIS!@#' })


Vue.use(VueRouter)

const routes = [

  {
    path: "/login",
    component: () => import("@/components/login/Login.vue"),
    name: 'Login',
    meta: { requiresAuth: false, public: true }
  },

  componentsRouter,

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})









router.beforeEach((to, from, next) => {





  store.dispatch('setRouter', to.path)

  let title = (to.name !== undefined || to.name !== null) ? `${to.name} |` : ""



  document.title = ` ${title} ${process.env.VUE_APP_ACRONYM}`



  const loggedIn = Object.keys(store.getters.getCurrentUser).length > 0 ? true : false;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isPublic = to.matched.some(record => record.meta.public)



  if (requiresAuth) {

    if (loggedIn) {

      if (canActivateRouter(to.path)) {

        next();
      } else {
        next('/')
      }




    } else {
      next("/login");

    }
  } else if (isPublic && loggedIn) {
    //A Logged-in user can't go to login page again

    next("/");
  } else {
    next();
  }
});

export default router


const canActivateRouter = path => {

  const user = ls.get("eExam-user");
  const menu = user && user.menus ? user.menus : [];


  let url = store.getters.currentRouter;

  let parentUrl = (path === "/") ? true : false;


  let title = {}

  if (parentUrl) {
    //for parent menu logic
    title = menu.find(r => r.url === "/")
  } else {
    /** menuItems Logic */

    title = menu.flatMap(el => el.submenus).find(p => p.url.includes("/") ? p.url === path : p.url === url.replace("/", "")
    )
  }


  if (title) {

    return true;
  } else {
    return false;
  }

  // console.log('toRouter :', toRouter)

}