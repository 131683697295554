import axios from "axios";
import SecureLs from 'secure-ls';

const ls = new SecureLs({ encodingType: 'des', isCompression: false, encryptionSecret: 'eAMIS!@#' })

let categories = ls.get("eExam-categories");

const ENDPOINT = "/api/v1/data-categories";

const state = {
  categories: [],
  searchResults: [],
  //allCategories: JSON.parse(localStorage.getItem("eExam-categories")),
  allCategories: categories,
  params: {
    page: 0,
    size: 20
  },
  success: null,
  error: null
};

const getters = {
  getCategories: state => {
    return state.categories;
  },

  getSearchResults: state => {
    return state.searchResults;
  },

  getParams: state => {
    return state.params;
  },

  getAllCategories: state => {
    return state.allCategories;
  }
};

const actions = {


  async getAll({ commit }, payload) {
    await axios.get(ENDPOINT, { params: payload.params }).then(response => {
      commit("SET_ALL_CATEGORIES", response.data[0]);
    });
  },

  async create({ commit }, payload) {
    await axios
      .post(ENDPOINT, payload)
      .then(response => {
        commit("UPDATE_AFTER_CREATE", { payload: payload, response: response });
      })
      .catch(err => {
        commit("SET_ERROR", err);
      });
  },

  async update({ commit }, payload) {
    await axios
      .put(ENDPOINT, payload)
      .then(response => {
        if (response.status == 200) {
          commit("UPDATE_AFTER_UPDATE", {
            payload: payload,
            response: response
          });
        }
      })
      .catch(err => {
        commit("SET_ERROR", err);
      });
  },

  async delete({ commit }, payload) {
    await axios
      .delete(ENDPOINT + "/" + payload.id)
      .then(response => {
        if (response.status == 200) {
          commit("UPDATE_AFTER_DELETE", {
            payload: payload,
            response: response
          });
        }
      })
      .catch(err => {
        commit("SET_ERROR", err);
      });
  },

  async search({ commit }, params) {
    await axios
      .get(params)
      .then(response => {
        commit("SET_SEARCH_RESULTS", response.data);
      })
      .catch(err => {
        return err;
      });
  },

  async getCategoryOptions(state, code) {

    let category = null;

    let categories = (await ls.get("eExam-categories")) || [];
    let existsCode = categories.find(cat => cat.code === code);
    if (existsCode) {

      category = categories.find(cat => cat.code === code);
      //console.log('code is:',code)
      // console.log('cate are:', category)
    } else {
      let params = {
        search: code
      };

      await axios.get(ENDPOINT, { params: params }).then(response => {
        // console.log('Response',response)
        let categories = response.data[0].data;

        category = categories.find(cat => cat.code == code);
      });
    }
    return category ? category.options : [];
  },


};

const mutations = {
  SET_RESPONSE(state, response) {
    state.params.total = response.total;
    state.categories = response.data;
  },

  SET_ALL_CATEGORIES(state, response) {
    // console.log('setCate',response)
    ls.set("eExam-categories", response.data);
    state.allCategories = response.data;
  },

  SET_ERROR(state, response) {
    state.error = response;
  },

  SET_SUCCESS(state, response) {
    state.success = response;
  },

  SET_SEARCH_RESULTS(state, response) {
    state.searchResults = response.data;
  },

  async UPDATE_AFTER_UPDATE(state, data) {
    let categories = await ls.get("eExam-categories");
    let category = categories.find(cat => cat.code == data.payload.code);
    let index = categories.indexOf(category);
    categories[index] = data.payload;
    ls.set("eExam-categories", categories);
    state.allCategories = categories;
  },

  async UPDATE_AFTER_CREATE(state, data) {
    let categories = await ls.get("eExam-categories");
    categories.push(data.payload);
    ls.set("eExam-categories", categories);
    state.allCategories = categories;
  },

  async UPDATE_AFTER_DELETE(state, data) {
    let categories = await ls.get("eExam-categories");
    categories = categories.filter(cat => cat.code == !data.payload.code);
    ls.set("eExam-categories", categories);
    state.allCategories = categories;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
