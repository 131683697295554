const examinationsRoutes = [
  {
    path: "/manage-examinations",
    name: 'Manage Exams',
    component: () => import("../ManageExams"),
    meta: { requiresAuth: true }
  },


];

export default examinationsRoutes;
