const studentsRoutes = [
  {
    path: "/manage-students",
    name: 'Student Registration',
    component: () => import("../Students"),
    meta: { requiresAuth: true }
  },


];

export default studentsRoutes;
