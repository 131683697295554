const schoolSubjectsRoutes = [
  {
    path: "/school-subjects",
    name: 'School Subjects',
    component: () => import("../SchoolSubjects"),
    meta: { requiresAuth: true }
  },


];

export default schoolSubjectsRoutes;
