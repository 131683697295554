
const state = {
  sweetAlerts: {
    title: '',
    text: "",
    icon: "",
  }
};

const getters = {
  getSweetAlert: state => {
    return state.sweetAlerts;
  }
};

const actions = {
  notify({ commit }, payload) {
    console.log(payload)
    commit("setSweetAlert", payload)
  }
};

const mutations = {
  setSweetAlert(state, payload) {
    state.sweetAlerts = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
