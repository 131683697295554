import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/Auth";
import Helper from './modules/Helper'
import DataCategory from './modules/DataCategory'
import SweetAlert from "./modules/SweetAlert"


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Helper,
    DataCategory,
    SweetAlert,
  },

});
