const examGradesRoutes = [
  {
    path: "/examination-grading",
    name: 'Exams Grading',

    component: () => import("../ExamsGrades"),
    meta: { requiresAuth: true }
  },


];

export default examGradesRoutes;
