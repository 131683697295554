<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    dark
    color="primary"
    app
  >
    <v-app-bar-nav-icon @click="toggleDrawe()"></v-app-bar-nav-icon>

    <v-toolbar-title>TS-MIS</v-toolbar-title>

    <v-spacer></v-spacer>
    <!-- <v-color-picker
      @input="getColor($event)"
      mode="hexa"
      hide-inputs
      hide-mode-switch
      dot-size="13"
      v-if="isOpen"
    ></v-color-picker> -->
    <v-col cols="2" sm="2" md="2">
      <v-sheet class="primary">
        <!-- <v-text-field
          v-model="selectedColor"
          label="Select Theme"
          :items="colors"
          :item-text="'name'"
          @click="open()"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          clear-icon="mdi-close-circle-outline"
          return-object
        ></v-text-field>

        <v-color-picker
          @input="getColor($event)"
          mode="hexa"
          hide-inputs
          hide-mode-switch
          dot-size="13"
          v-if="isOpen"
        ></v-color-picker> -->
        <v-text-field
          v-model="theme.hex"
          hide-details
          dark
          flat
          solo-inverted
          clearable
        >
          <template v-slot:append>
            <v-menu
              v-model="menu"
              top
              nudge-bottom="105"
              nudge-left="16"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div :style="swatchStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker
                    mode="hexa"
                    flat
                    @input="getColor($event)"
                    dot-size="13"
                    hide-inputs
                    hide-mode-switch
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </v-sheet>
    </v-col>
    <v-col cols="2" sm="2" md="2">
      <v-sheet class="primary">
        <v-text-field
          v-model="search"
          label="Search"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
      </v-sheet>
    </v-col>

    <v-btn text value="logout" @click="signOut">
      <span class="font-weight-bold">Logout</span>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>
<script>
export default {
  components: {},

  props: {
    toggleDrawer: { type: Function },
    drawer: { type: Boolean },
    logout: { type: Function },
    setThemeColor: { type: Function },
    isOpen: { type: Boolean, default: false },
  },
  name: "Header",
  data: () => ({
    search: null,
    selectedColor: null,
    color: "#009688",
    mask: "!#XXXXXXXX",
    menu: false,
    theme: {
      hex: "#009688",
    },
    colors: [
      { name: "Red", code: "#f44336" },
      { name: "Pink", code: "#e91e63" },
      { name: "Purple", code: "#9c27b0" },
      { name: "DeepPurple", code: "#673ab7" },
      { name: "Indigo", code: "#3f51b5" },
      { name: "Blue", code: "#2196f3" },
      { name: "LightBlue", code: "#03a9f4" },
      { name: "Cyan", code: "#00bcd4" },
      { name: "Teal", code: "#009688" },
      { name: "Green", code: "#4caf50" },
      { name: "LightGreen", code: "#8bc34a" },
      { name: "Lime", code: "#cddc39" },
      { name: "Yellow", code: "#ffeb3b" },
      { name: "Amber", code: "#ffc107" },
      { name: "Orange", code: "#ff9800" },
      { name: "DeepOrange", code: "#ff5722" },
      { name: "Brown", code: "#795548" },
      { name: "BlueGrey", code: "#607d8b" },
      { name: "Grey", code: "#9e9e9e" },
      { name: "shades", code: "#000000" },
    ],
  }),

  methods: {
    toggleDrawe() {
      this.toggleDrawer(this.drawer);
    },
    signOut() {
      this.logout();
    },

    setTheme(data) {
      // data = JSON.stringify(data);

      this.$mixins.setItem("theme", data);

      let color = this.$mixins.getItem("theme");

      this.setThemeColor(color);

      this.selectedColor = color.name;
    },

    getColor(data) {
      this.$mixins.setItem("theme", data);
      let color = this.$mixins.getItem("theme");

      this.setThemeColor(color, "isHex");
      this.theme = color;
    },
  },

  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },

  mounted() {},

  created() {
    let color = this.$mixins.getItem("theme");
    this.selectedColor = color.name; // getter's value
  },
};
</script>

<style scoped></style>
