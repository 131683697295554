<template>
  <v-app id="app">
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <SideBar :drawer="drawer" />
    <Header
      :toggleDrawer="toggleDrawer"
      :logout="logOut"
      :drawer="drawer"
      :setThemeColor="setThemeColor"
    />
    <Loader :is-visible="isLoading"></Loader>
    <SnackBar />
    <SweetAlert />
    <reset-password
      :dialog="dialogVisible"
      :closeDialog="closeResetDialog"
      :save-function="savePassword"
      :loading="isLoading"
      :formData="formData"
    />
  </v-app>
</template>
<script>
import axios from "axios";
import Header from "./Header";
import SideBar from "./SiderBar";
import Dashboard from "../dashboard/Dashboard";
import ResetPassword from "../users/Forms/ChangeUserPasswordDialo.vue";
import { changeDefaultPassword } from "../users/services";

export default {
  name: "Home",
  data: () => {
    return {
      drawer: true,
      axiosInterceptor: null,
      isLoading: false,
      dialogVisible: false,
      formData: {},
      // themeColor: this.$store.commit("getItem","theme"),
    };
  },
  components: {
    SideBar,
    Header,
    Dashboard,
    ResetPassword,
  },
  methods: {
    setThemeColor(data, type = null) {
      if (type === "isHex") {
        this.$vuetify.theme.themes.light.primary = data.hex;
        this.$vuetify.theme.themes.dark.primary = data.hex;
      } else {
        this.$vuetify.theme.themes.light.primary = data.code;
        this.$vuetify.theme.themes.dark.primary = data.code;
      }
    },
    toggleDrawer() {
      return (this.drawer = !this.drawer);
    },

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use(
        (config) => {
          this.isLoading = true;
          return config;
        },
        (error) => {
          this.isLoading = false;
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          this.changePassword();
          this.isLoading = false;
          return response;
        },
        (error) => {
          this.isLoading = false;
          return Promise.reject(error);
        }
      );
    },

    disableInterceptor() {
      this.changePassword();
      axios.interceptors.request.eject(this.axiosInterceptor);
    },

    changePassword() {
      if (
        this.user?.password_changed == null ||
        this.user?.password_changed == false
      )
        this.dialogVisible = true;
    },

    reset() {
      this.dialogVisible = true;
    },

    closeResetDialog() {
      this.logOut();
    },

    savePassword(data) {
      data.email = this.user.email;
      data.id = this.user.id;

      changeDefaultPassword(data).then(() => {
        this.logOut();
      });
    },
    logOut() {
      this.$store.dispatch("logout").then((response) => {
        this.dialogVisible = false;
        this.$router.push({ path: "/login" });
      });
    },
  },
  computed: {
    user() {
      return this.$mixins.user();
    },
  },
  mounted() {
    this.enableInterceptor();
    this.changePassword();
  },
};
</script>
