import axios from "axios";
const ENDPOINT = "/api/v1/users";

const get = async payload => {
  return axios.get(ENDPOINT, { params: payload });
};

const exists = async payload => {
  return axios.get(ENDPOINT + '/exists', { params: payload });
};

const create = async payload => {
  return await axios.post(ENDPOINT, payload);
};

const update = async payload => {
  return await axios.put(ENDPOINT, payload);
};

const remove = async payload => {
  return axios.delete(ENDPOINT + "/" + payload);
};

const reset_password = async payload => {
  return axios.post(ENDPOINT + "/reset-password", payload);
};


const changeDefaultPassword = async payload => {
  return axios.post(ENDPOINT + "/change-default-password", payload);
};


export { get, create, update, remove, reset_password, changeDefaultPassword, exists };
