const permissionRoutes = [
  {
    path: "/permissions",
    name: 'Permissions',
    component: () => import("../Permission"),
    meta: { requiresAuth: true }
  },


];

export default permissionRoutes;
