const cacheRoutes = [
  {
    path: "/clear-cache",
    component: () => import("../AppCachConfig.vue"),
    meta: { requiresAuth: true }
  },


  {
    path: "/optimize",
    component: () => import("../AppCachConfig.vue"),
    meta: { requiresAuth: true }
  },

  {
    path: "/routes-clear",
    component: () => import("../AppCachConfig.vue"),
    meta: { requiresAuth: true }
  },


];

export default cacheRoutes;
